import React from 'react';

import settings from '@settings';
import PropTypes from 'prop-types';

const SmallLabel = ({text, color}) => {
	const smallLabel = {
		fontFamily:  'Open Sans',
		fontWeight:600,
		fontSize: settings.fontSize.semismall,
		lineHeight: 1.4,
		letterSpacing: '-0.5%',
		color,
		marginTop: settings.sizes.medium
	};
    
	return (
		<div css={smallLabel}>{text}</div>
	);
};

SmallLabel.propTypes = {
	text: PropTypes.string.isRequired,
	color: PropTypes.string
};

SmallLabel.defaultProps = {
	color: settings.colors.grey
};

export default SmallLabel;