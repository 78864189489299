import settings from '@settings';

export const newsCardContainer = settings.mq({
	width: ['100%', '100%', '360px'], 
	backgroundColor: settings.colors.white,
	transition: 'height 0.6s ease',
	height: 'auto',
	position: 'relative',
	// boxShadow: settings.shadows.regular,
	overflow: 'hidden',
	color: settings.colors.dark,
	alignSelf:'flex-start',
	border: `1px solid ${settings.colors.grey}`
	// borderRadius: settings.borderRadius.medium
});

export const newsCardImage =  {
	borderRadius: settings.borderRadius.medium,
	overflow: 'hidden',
	flexyGrow: 0
};

export const newsCard = {
	width: '360px', 
	cursor: 'pointer',
	transition: 'height 0.6s ease'
};

export const newsCardContentContainer = {
	padding: settings.padding.medium,
	marginTop: settings.sizes.medium,
	minHeight: '120px'
};

export const labelContainer = {
	position: 'absolute',
	bottom: settings.sizes.small,
	left: settings.sizes.large
};

export const textContainer = {
	overflow: 'hidden', 
	marginTop: settings.sizes.medium, 
	marginBottom: settings.sizes.medium 
};