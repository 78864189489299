import Button from '@components/buttons/Button';
import FlexBox from '@components/layout/FlexBox';
import AthleteCard from '@components/surfaces/AthleteCard';
import useGetAthlete from '@hooks/useGetAthlete';
import useGetColors from '@hooks/useGetSiteColors';
import settings from '@settings';
import { navigate } from 'gatsby';
import React from 'react';

const HomeAthletes = () => {
	const {athletes} = useGetAthlete();
	const {primary, secondary} = useGetColors();
	
	const randomAthletesHandler = (athletesToFilter) =>{
		let randomAthleteArray = [];
		const randomIndexArray = [];
		let i = 1;
		if (athletesToFilter.length > 2) {
			randomAthleteArray = [];
			while (i < 4) {
				const randomIndex = Math.floor((Math.random()*athletesToFilter.length));
				if (!randomIndexArray.includes(randomIndex)) {
					
					randomIndexArray.push(randomIndex);
					i +=1; 
				}
			}
			randomIndexArray.forEach(index => randomAthleteArray.push(athletesToFilter[index]));
		} else {
			athletesToFilter.forEach(athlete => randomAthleteArray.push(athlete));
		}
		return randomAthleteArray;
	};

	return (
		<FlexBox direction='column' align='center' gap={settings.sizes.medium}>
			<h2>
				<span css={{color: primary}}>Athletes</span> 
			</h2>
			<p css={{textAlign: 'center'}}>
				Get Information about the Athletes
			</p>
			<FlexBox
				direction='row'
				align='stretch'
				justify='space-between'
				gap={settings.sizes.medium}
				hasFullWidth
				changeToColumn
				styles={{marginBottom: settings.sizes.large, alignItems: ['center', 'center', 'stretch']}}
			>
				{randomAthletesHandler(athletes).map(athlete =>
					<AthleteCard 
						name={athlete.name}
						portrait={athlete.portrait}
						nation={athlete.nation}
						description={athlete.description}
						key={athlete.id}

					/>
				)}
			</FlexBox>
			<Button
				size='large'
				color={settings.colors.tertiary}
				hoverColor={secondary}
				text='View All Athletes'
				onClick={() => navigate('/athletes')}
			/>
		</FlexBox>
	);
};

export default HomeAthletes;