import create from 'zustand';

const useSiteContext = create((set) => ({
	home: {
		galleryPictures: 4,
		playerCards: 3,
		newsCards: 3

	}

}));

export default useSiteContext;