import React from 'react';

import Container from '@components/Container';
import Wrapper from '@components/layout/Wrapper';
import Layout from '@mainComponents/Layout';
import ToTop from '@mainComponents/Layout/components/ToTop';
import settings from '@settings';
import PropTypes from 'prop-types';

import HomeAthletes from './content/HomeAthletes';
import HomeGallery from './content/HomeGallery';
import HomeNews from './content/HomeNews';

const Home = ({ pageContext: {uri} }) => (
	<Layout
		pageTitle={'Womens \n Nordic \n Combined'}
		headerType='home'
		showHeader
		path={uri}
	>
		<ToTop />

		<Wrapper background={settings.colors.light}  >
			<Container>
				<HomeNews />
			</Container>
		</Wrapper>

		<Wrapper background={settings.colors.darkgrey}>
			<Container>
				<HomeGallery />
			</Container>
		</Wrapper>

		<Wrapper background={settings.colors.light} >
			<Container>
				<HomeAthletes />
			</Container>
		</Wrapper>
		
	</Layout>
);



Home.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default Home;