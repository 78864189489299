/* eslint-disable react/prop-types */
import React from 'react';

import Home from '@content/Home';
import SiteHead from '@mainComponents/SiteHead';

const IndexPage = pageContext => <Home pageContext={pageContext} />;

export const Head = ({location}) => <SiteHead location={location} />;

export default IndexPage;
