import settings from '@settings';

export const athleteCardContainer = settings.mq({
	width: ['100%', '100%', '400px'], 
	maxWidth: '360px',
	backgroundColor: settings.colors.white,
	height: 'auto',
	color: settings.colors.dark,
	// borderRadius: settings.borderRadius.medium,
	border: `1px solid ${settings.colors.grey}`,
	overflow: 'hidden',
	// boxShadow: settings.shadows.regular
	transition: 'height 0.6s ease, box-shadow .4s ease',
	':hover': {
		boxShadow: settings.shadows.hover
	}
	
});

export const athelteCardImage =  {
	borderRadius: settings.borderRadius.medium,
	overflow: 'hidden'
};

export const athleteCard = {
	width: '360px', 
	cursor: 'pointer',
	backgroundColor: settings.colors.light,
	transition: 'height 0.6s ease'
};

export const athleteCardContentContainer = {
	padding: settings.padding.large,
	marginTop: settings.sizes.medium,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: settings.sizes.medium
};