import React, {useEffect, useState} from 'react';

import useGetAthlete from '@hooks/useGetAthlete';
import useGetColors from '@hooks/useGetSiteColors';
import settings from '@settings';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import { labelContainer, newsCardContainer, newsCardContentContainer, textContainer  } from './styles';
import SmallLabel from '../SmallLabel';

const NewsCard = ({id, title, image, date, content }) => {
	const {secondary} = useGetColors();
	const [isExpanded, setIsExpanded] = useState(false);
	const renderImage = getImage(image);
	const {getAthlete} = useGetAthlete();

	useEffect(() => {
	  	const contentContainer = document.getElementById(`${id}-content`);
		const links = contentContainer.getElementsByTagName('a');
		for (let n = 0; n< links.length; n+= 1 ) {
			if (getAthlete(links.item(n).dataset.gcmsEmbedId)) {
				links.item(n).href= `./athletes/${settings.functions.slugify(getAthlete(links.item(n).dataset.gcmsEmbedId)?.name)}`;
			}
		}
	}, [getAthlete, id]);

	const toggleOpen = () => setIsExpanded(!isExpanded);

	return (
		<motion.div
			css={[newsCardContainer, {
				'h3': {
					':hover': {
						color: secondary,
						flexGrow: 1
					}
				}
			}]}
			onClick={() => toggleOpen()}
			layout
			key={id}
		>
			{renderImage && <GatsbyImage autoSave='true' alt={title} image={renderImage} />}
			<div css={newsCardContentContainer}>
				<motion.h3 className='is-link' >{title}</motion.h3>
				<motion.div
					css={textContainer}
					style={{height:0}}
					animate={{height: !isExpanded ? 0 : 'auto'}}
				>
					<div id={`${id}-content`} dangerouslySetInnerHTML={{__html: content.html}} />
				</motion.div>
				<div css={labelContainer}>
					<SmallLabel text={settings.functions.transformDate(date)} color={settings.colors.grey} />
				</div>
			</div>

		</motion.div>
	);
};

NewsCard.propTypes = {
	id: PropTypes.string.isRequired, 
	title: PropTypes.string.isRequired, 
	image: PropTypes.object.isRequired, 
	date: PropTypes.string.isRequired, 
	content: PropTypes.object.isRequired
};

export default NewsCard;