import Button from '@components/buttons/Button';
import FlexBox from '@components/layout/FlexBox';
import NewsCard from '@components/surfaces/NewsCard';
import useGetNews from '@hooks/useGetNews';
import useGetColors from '@hooks/useGetSiteColors';
import settings from '@settings';
import { navigate } from 'gatsby';
import React from 'react';

const HomeNews = () => {
	const news = useGetNews();
	const {primary, secondary} = useGetColors();

	return (
		<FlexBox direction='column' align='center' gap={settings.sizes.medium}>
			<h2>
				Get The latest <span css={{color: primary}}>News</span> 
			</h2>
			<p css={{textAlign: 'center'}}>
				Recent Information about competitions, athletes and Women’s Nordic Combined
			</p>
			<FlexBox
				direction='row'
				justify='space-between'
				align='stretch'
				gap={settings.sizes.medium}
				styles={{marginBottom: settings.sizes.large}}
				hasFullWidth
				changeToColumn
			>
				{news.slice(0, 3).map(newsItem =>
					<NewsCard
						id={newsItem.remoteId}
						key={newsItem.id}
						title={newsItem.title}
						image={newsItem.image}
						date={newsItem.date}
						content={newsItem.content}
					/>
				)}
			</FlexBox>
			<Button 
				color={settings.colors.dark}
				hoverColor={secondary}
				text='See all News'
				onClick={() => navigate('/news')}
				size='large'
			/>

		</FlexBox>
	);
};

export default HomeNews;