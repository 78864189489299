import React from 'react';

import IconButton from '@components/buttons/IconButton';
import useGetColors from '@hooks/useGetSiteColors';
import settings from '@settings';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { HiArrowLongRight } from 'react-icons/hi2';

import { athleteCardContainer, athleteCardContentContainer } from './styles';
import Divider from '../Divider';

const AthleteCard = ({name, portrait, nation}) => {
	const renderImage = getImage(portrait);
	const {secondary} = useGetColors();

	const icon = () => <HiArrowLongRight />;

	return (
		<div
			css={athleteCardContainer}
		>
			<div css={{width: '100%', height: '202.5px', overflow: 'hidden', backgroundColor: settings.colors.lightgrey}}>
				{portrait && <GatsbyImage alt={name} image={renderImage} style={{height:'100%'}}/>}
			</div>
			<div css={athleteCardContentContainer}>
				<h3>
					{name}
				</h3>
				{nation &&
					<p>
						<img src={nation.flag.url} alt={nation.name} height={12} /> {nation.short}
					</p>
				}
				<Divider gap='small' />
				<IconButton
					text='Visit Page'
					onClick={() => navigate(`/athletes/${settings.functions.slugify(name)}`)}
					size='medium'
					icon={icon()}
					color={settings.colors.dark}
					hoverColor={secondary}
				/>
			</div>
		</div>
	);
};

AthleteCard.propTypes = {
	name: PropTypes.string.isRequired, 
	portrait: PropTypes.object, 
	nation: PropTypes.object
};

AthleteCard.defaultProps = {
	nation: undefined,
	portrait: null
};

export default AthleteCard;