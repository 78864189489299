import { graphql, useStaticQuery } from 'gatsby';

const useGetNews = () => {
	const newsData = useStaticQuery(graphql`
        query newsQuery {
            allGraphCmsNewsItem(sort: {fields: date, order: DESC}) {
                edges {
                    node {
                        id
                        date
                        remoteId
                        title
                        pin
                        createdAt
                        content {
                            html
                            references {
                                ... on GraphCMS_Athlete {
                                    id
                                    name
                                }
                            }
                        }
                        image {
                            gatsbyImageData(
                                aspectRatio: 1.5, 
                                quality: 90, 
                                height: 360,
                            )
                        }

                    }
                }
                
            }
        }
    `);

	const newsSortHandler = news => {
		const newsArray = [...news];

		newsArray.sort((a, b) => {
			if (a.pin) {
				return -1;
			}
			if (b.pin) {
				return 1;
			}
			if (a.date > b.date) {
				return -1;
			}
			if (a.date < b.date) {
				return 1;
			}
			if (a.date === b.date) {
				const createDataA = new Date(a.createdAt);
				const createDataB = new Date(b.createdAt);
				return createDataB - createDataA;
			}
			return 0;
		});

		return newsArray;
	};

	return newsSortHandler(newsData.allGraphCmsNewsItem.edges.map(item => item.node ));
};

export default useGetNews;