import Button from '@components/buttons/Button';
import FlexBox from '@components/layout/FlexBox';
import ImageGallery from '@components/surfaces/ImageGallery';
import useGetImages from '@hooks/useGetImages';
import useGetColors from '@hooks/useGetSiteColors';
import useSiteContext from '@hooks/useSiteContext';
import settings from '@settings';
import { navigate } from 'gatsby';
import React, { useMemo } from 'react';

const HomeGallery = () => {
	const {images} = useGetImages();
	const {home} = useSiteContext();
	const {primary, secondary} = useGetColors();

	const homeImages = useMemo(() => {
		const imageArray = []; 
	  images.forEach(image => {
			if (image.start) {
				if (image.start === true) {
					return imageArray.push(image);
				}
				
			}
			return null;
	  });
	
	  return imageArray;
	}, [images]);

	return (
		<FlexBox
			direction='column'
			align='center'
			gap={settings.sizes.large}
			styles={{color: settings.colors.white}}
		>
			<h2>
				Visit our <span css={{color: primary}}>Gallery</span> 
			</h2>
			<p css={{textAlign: 'center'}}>
				Recent Information about competitions, players and Women’s Nordic Combined
			</p>
			<FlexBox
				direction='row'
				align='center'
				justify='space-between'
				hasFullWidth
				changeToColumn
			>
				<ImageGallery 
					images={homeImages.slice(0, home.galleryPictures)}
				/>
				
			</FlexBox>
			<Button 
				color={settings.colors.white}
				hoverColor={secondary}
				text='Go To Gallery'
				size='large'
				onClick={() => navigate('/gallery')}
			/>
		</FlexBox>
	);
};

export default HomeGallery;